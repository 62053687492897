import React, { useState } from "react";
import { Field, useField } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import styles from "./passwordInput.module.scss";
import GenericField from "@components/formFields/GenericField";

interface PasswordInputProps {
  className?: string;
  isDisabled?: boolean;
  key?: string;
  label?: string;
  name: string;
  placeholder?: string;
  "test-id"?: string;
}

const PasswordInput = ({
  className,
  name,
  label,
  ...props
}: PasswordInputProps) => {
  const [seePassword, setSeePassword] = useState(false);
  const [field] = useField(name);

  function buildSeePassword() {
    switch (seePassword) {
      case false:
        return (
          <FaEye onClick={() => setSeePassword(true)} data-testid="showBtn" />
        );
      case true:
        return (
          <FaEyeSlash
            onClick={() => setSeePassword(false)}
            data-testid="hideBtn"
          />
        );
    }
  }

  return (
    <GenericField name={name} label={label}>
      <div className={styles.passwordInput}>
        <Field
          as="input"
          data-testid="passwordInput"
          type={seePassword ? "text" : "password"}
          {...field}
          {...props}
        />
        {buildSeePassword()}
      </div>
    </GenericField>
  );
};

export default PasswordInput;
