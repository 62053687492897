import Button from "@components/buttons/Button";
import ButtonLink from "@components/buttons/ButtonLink";
import PasswordInput from "@components/formFields/PasswordInput";
import TextInput from "@components/formFields/TextInput";
import FullPageLoader from "@components/FullPageLoader";
import HrOr from "@components/HrOr";
import LoginSideBar from "@components/sideBar/LoginSideBar";
import SocialLogins from "@components/SocialLogins";
import StylizedLink from "@components/StylizedLink";
import { useAuth } from "@lib/authContext";
import { loginEvent } from "@lib/gtm";
import { SSO_API } from "@lib/urls";
import iconCloud from "@public/icon-cloud.svg";
import { Form, Formik, FormikHelpers } from "formik";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import * as Yup from "yup";
import styles from "./login.module.scss";
import { USE_SOCIAL_SUBSCRIPTION } from "@lib/constants";

interface Values {
  email: string;
  password: string;
}

const loginValidator = Yup.object().shape({
  email: Yup.string()
    .required("Esse campo é obrigatório")
    .email("Insira um endereço de email válido"),
  password: Yup.string().required("Esse campo é obrigatório"),
});

export default function Login() {
  const router = useRouter();
  const auth = useAuth();
  const captchaRef = useRef(null);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const { apikey } = router.query; // Single Sign-On public API Key
  const RECAPTCHA_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_KEY;
  const captchaEnabled = RECAPTCHA_KEY != undefined;

  const singleSignOn = useCallback(async () => {
    const response = await auth.privateClient!.post(SSO_API, {
      api_key: apikey,
    });

    window.location.href = `${response.data.redirect_url}${response.data.token}/`;
  }, [apikey, auth.privateClient]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (apikey) {
        singleSignOn();
      } else {
        const nextUrl = router.query.nextURL || "/dashboard";
        router.replace(nextUrl.toString());
      }
    } else {
      router.prefetch("/dashboard");
    }
  }, [router, auth.isAuthenticated, apikey, singleSignOn]);

  async function handleSubmit(
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) {
    try {
      await auth.login(values);
      loginEvent("email");
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        alertLoginBlocked(err);
      } else {
        toast.error(err.message);
      }
    }
    setSubmitting(false);
  }

  function onCaptchaChange(value: string | null) {
    setCaptchaValue(value);
  }

  return auth.isAuthenticated ? (
    <FullPageLoader />
  ) : (
    <div className={styles.loginContainer} data-testid="login">
      <LoginSideBar />

      <section className={styles.loginContent}>
        <div>
          <div className={styles.logo}>
            <Link href="/">
              <a>
                <Image src={iconCloud} alt="Segurize Icon" height={45} />
              </a>
            </Link>
          </div>

          <h2>Acesse a Segurize</h2>
          {USE_SOCIAL_SUBSCRIPTION && (
            <>
              <SocialLogins title="Acesse com as redes sociais:" />
              <HrOr />
            </>
          )}
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginValidator}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <TextInput
                  label="Email: "
                  name="email"
                  placeholder="Insira seu endereço de email"
                />
                <PasswordInput
                  label="Senha: "
                  name="password"
                  placeholder="Insira sua senha"
                />
                <StylizedLink alignDirection="right" to="/forgot-password">
                  Esqueci minha senha
                </StylizedLink>
                {captchaEnabled && (
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_KEY!}
                    onChange={onCaptchaChange}
                    ref={captchaRef}
                  />
                )}
                <Button
                  disabled={
                    !formik.isValid ||
                    formik.isSubmitting ||
                    (captchaEnabled && captchaValue == null)
                  }
                  isLoading={formik.isSubmitting}
                  onClick={formik.handleSubmit}
                  themeColor="secondary"
                  type="submit"
                >
                  Entrar &rarr;
                </Button>
              </Form>
            )}
          </Formik>

          <ButtonLink to="/" themeColor="secondaryOutline">
            Ainda não tenho conta
          </ButtonLink>
        </div>
      </section>
    </div>
  );
}

function alertLoginBlocked(err: any) {
  if (err.response.data?.detail?.failure_limit) {
    let colloffTimeMinutes = err.response.data.detail.cooloff_time_seconds / 60;
    toast.error(
      <>
        Usuário bloqueado por multiplas tentativas de login. Tente novamente em{" "}
        {colloffTimeMinutes} minutos.
      </>
    );
  } else {
    toast.error(
      <>Usuário ou senha inválidos. Tente novamente ou recupere sua senha.</>
    );
  }
}
