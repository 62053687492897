import { AxiosError } from "axios";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { publicClient } from "@lib/api";
import { useAuth } from "@lib/authContext";
import { loginEvent } from "@lib/gtm";
import { SOCIAL_LOGIN_URL } from "@lib/urls";
import FacebookLoginBtn from "../FacebookLoginBtn";
import GoogleLoginBtn from "../GoogleLoginBtn";
import LoadingDots from "../LoadingDots";
import styles from "./socialLogins.module.scss";

interface SocialLoginsProps {
  title: string;
}

const SocialLogins: React.FC<SocialLoginsProps> = ({ title }) => {
  const auth = useAuth();

  const postToken = (variables: { provider: string; token: string }) => {
    const url = SOCIAL_LOGIN_URL + variables.provider + "/";
    return publicClient.post(
      url,
      { access_token: variables.token },
      { withCredentials: true }
    );
  };

  const socialBtnsVariable = { "--numOfSocialBtns": 2 } as React.CSSProperties;

  const mutation = useMutation(postToken, {
    onSuccess: (result, variables, context) => {
      // if it's a login authentication:
      loginEvent(variables.provider);
      // if it's a signup authentication:
      // signupEvent("google");
      auth.tokenLogin(result.data);
    },
    onError: (error: AxiosError, variables, context) => {
      if (error.response && error.response.status === 403) {
        toast.error(
          "Não foi possível encontrar um usuário associado a essa conta."
        );
      } else {
        toast.error(error.message);
      }
    },
  });

  return (
    <div className={styles.socialLoginContainer}>
      <p>{title}</p>
      {mutation.isLoading ? (
        <LoadingDots color height={40} />
      ) : (
        <div className={styles.socialBtns} style={socialBtnsVariable}>
          <FacebookLoginBtn
            onLogin={(token) =>
              mutation.mutate({ provider: "facebook", token: token })
            }
          />
          <GoogleLoginBtn
            onLogin={(token) =>
              mutation.mutate({ provider: "google-oauth2", token: token })
            }
          />
        </div>
      )}
    </div>
  );
};

export default SocialLogins;
