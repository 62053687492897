import GenericField from "@components/formFields/GenericField";

interface TextInputProps {
  className?: string;
  label?: string;
  name: string;
}

export default function TextInput({
  className,
  name,
  label,
  ...props
}: TextInputProps & React.InputHTMLAttributes<HTMLInputElement>): JSX.Element {
  return (
    <GenericField
      componentType="input"
      data-testid={name}
      label={label}
      name={name}
      {...props}
    />
  );
}
