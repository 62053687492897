import Button from "@components/buttons/Button";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import React from "react";

interface GoogleLoginBtnProps {
  btnText?: string;
  style?: "flatTextSecondary" | "google";
  onLogin: (accessToken: string) => void;
  isLoading?: boolean;
}

const GoogleLoginBtn: React.FC<GoogleLoginBtnProps> = ({
  btnText = "Google",
  style = "google",
  onLogin,
  isLoading = false,
}) => {
  const login = useGoogleLogin({
    onSuccess: googleLogin,
  });

  function googleLogin(response: TokenResponse) {
    const accessToken = response.access_token;
    onLogin(accessToken);
  }

  return (
    <Button
      type="button"
      themeColor={style}
      onClick={login}
      isLoading={isLoading}
    >
      {btnText}
    </Button>
  );
};

export default GoogleLoginBtn;
