import React from "react";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Button from "../buttons/Button";

interface FacebookLoginBtnProps {
  btnText?: string;
  style?: "flatTextSecondary" | "facebook";
  onLogin: (accessToken: string) => void;
  isLoading?: boolean;
}

const FacebookLoginBtn: React.FC<FacebookLoginBtnProps> = ({
  btnText = "Facebook",
  style = "facebook",
  onLogin,
  isLoading = false,
}) => {
  async function facebookLogin(userInfo: ReactFacebookLoginInfo) {
    onLogin(userInfo.accessToken);
  }

  return (
    <FacebookLogin
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID!}
      autoLoad={false}
      fields={process.env.NEXT_PUBLIC_FACEBOOK_LOGIN_FIELDS!}
      reAuthenticate={true}
      callback={facebookLogin}
      render={({ onClick }) => (
        <Button
          type="button"
          themeColor={style}
          onClick={onClick}
          isLoading={isLoading}
        >
          {btnText}
        </Button>
      )}
    />
  );
};

export default FacebookLoginBtn;
